export const environment = {
    production: false,
    apiBaseUrl: 'https://stageapi.kpfs.co.in',
    apiUrl: 'https://stageapi.kpfs.co.in/api',
    environmentType: 'staging',
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'DD/MM/YYYY hh:mm:ss A',
    idleTime: 3000,
    idleTimeout: 3000
};
